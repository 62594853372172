/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser } from '@remix-run/react';
import { i18n } from '@server/localization/i18n';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HTTPBackend from 'i18next-http-backend';
import { startTransition } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { getInitialNamespaces } from 'remix-i18next/client';

function clearBrowserExtensionInjectionsBeforeHydration() {
    document
        .querySelectorAll(
            ['html > *:not(body, head)', 'script[src*="extension://"]', 'link[href*="extension://"]'].join(', '),
        )
        .forEach((s) => {
            s.parentNode?.removeChild(s);
        });

    const $targets = {
        html: {
            $elm: document.querySelector('html')!,
            allowedAttributes: ['lang', 'dir', 'class'],
        },
        head: {
            $elm: document.querySelector('head')!,
            allowedAttributes: [''],
        },
        body: {
            $elm: document.querySelector('body')!,
            allowedAttributes: ['class'],
        },
    };

    Object.entries($targets).forEach(([targetName, target]) => {
        target.$elm.getAttributeNames().forEach((attr) => {
            if (!target.allowedAttributes.includes(attr)) {
                target.$elm.removeAttribute(attr);
            }
        });
    });
}

async function hydrate() {
    clearBrowserExtensionInjectionsBeforeHydration();
    await i18next
        .use(initReactI18next) // Tell i18next to use the react-i18next plugin
        .use(LanguageDetector) // Setup a client-side language detector
        .use(HTTPBackend) // Setup your backend
        .init({
            ...i18n, // spread the configuration
            ns: getInitialNamespaces(),
            backend: {
                loadPath: '/locales/{{lng}}/{{ns}}.json',
                customHeaders: {
                    'cache-control': undefined,
                },
            },

            detection: {
                order: ['cookie', 'htmlTag'],
                caches: [],
            },
        });

    startTransition(() => {
        hydrateRoot(
            document,
            <I18nextProvider i18n={i18next}>
                <RemixBrowser />
            </I18nextProvider>,
        );
    });
}

if (window.requestIdleCallback) {
    window.requestIdleCallback(hydrate);
} else {
    // Safari doesn't support requestIdleCallback
    // https://caniuse.com/requestidlecallback
    window.setTimeout(hydrate, 1);
}
